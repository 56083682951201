export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBzpq-Y1P2woXmV4Cmy6zTVg6PyP4vU7qw',
    authDomain: 'staging.lisackepredplatne.cz',
    projectId: 'loyal-fox-staging-7cbcb',
    storageBucket: 'loyal-fox-staging-7cbcb.appspot.com',
    locationId: 'europe-west',
    messagingSenderId: '1023442445015',
    appId: '1:1023442445015:web:ea602c837f2bf31bd00692',
  },
  functionsUrl: 'https://europe-west1-loyal-fox-staging-7cbcb.cloudfunctions.net/',
  useEmulators: false,
  name: 'Staging',
  hotjarId: '',
  hostName: 'https://staging.lisackepredplatne.cz',
};
